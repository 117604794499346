// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDrFkedS9Wg-rqt6Zq02-MJrQPYMWDmGVk",
  authDomain: "buildart-industries-2801.firebaseapp.com",
  databaseURL: "https://buildart-industries-2801-default-rtdb.firebaseio.com",
  projectId: "buildart-industries-2801",
  storageBucket: "buildart-industries-2801.appspot.com",
  messagingSenderId: "238127367696",
  appId: "1:238127367696:web:3172687ecbb0d1c2842c95",
  measurementId: "G-HHYSZH54JM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { firebaseConfig };



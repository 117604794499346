// src/FirebaseProvider.js
import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import FirebaseContext from './FirebaseContext';
import { firebaseConfig } from './firebaseConfig';

const FirebaseProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const authInstance = getAuth(app);

    onAuthStateChanged(authInstance, (user) => {
      setAuth(user);
    });
  }, []);

  // Provide 'firebase' object instead of 'initializeApp' function
  return (
    <FirebaseContext.Provider value={{ firebase: { initializeApp }, auth }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import FirebaseProvider from './FirebaseProvider';

const rootElement = document.getElementById('root'); // Make sure 'root' is the ID of a valid DOM element
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <FirebaseProvider>
        <App />
      </FirebaseProvider>
    </React.StrictMode>
  );
  
  reportWebVitals();
} else {
  console.error("Root element with id 'root' not found.");
}

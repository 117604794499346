import React from 'react';
import mission from '/Users/hirenmasaliya/ReactDemo/buildart/src/Images/mission.webp'
import vision from "/Users/hirenmasaliya/ReactDemo/buildart/src/Images/vision.webp";
import quality from "/Users/hirenmasaliya/ReactDemo/buildart/src/Images/quality.png";
import innovation from "/Users/hirenmasaliya/ReactDemo/buildart/src/Images/innovation.png";
import customer from "/Users/hirenmasaliya/ReactDemo/buildart/src/Images/Customer_servies.png";
import '/Users/hirenmasaliya/ReactDemo/buildart/src/Css/About.css';

class About extends React.Component {
    state = {}
    render() {
        return (
            <>

                <div className='mission'>
                    <div className='main1'>
                        <h2>About Us</h2>

                        <div className='main3'>
                            <div className='main2'>
                                <h3>Our Mission</h3>
                                <img src={mission} alt='Hero' />
                                <h4>Our mission is to empower the construction industry with groundbreaking epoxy and grout solutions, fostering sustainable and resilient structures while prioritizing customer success.</h4>
                            </div>

                            <div className='main2'>
                                <h3>Our Vision</h3>
                                <img src={vision} alt='Hero' />
                                <h4>We envision a world where everyone has access to the highest quality epoxy and grout products. We believe that our products can help people build better homes, businesses, and communities.</h4>
                            </div>
                        </div>
                        <h2>Our Values</h2>
                        <div className='main3'>
                            <div className='main2'>
                                <h3>Quality</h3>
                                <img src={quality} alt='Hero' />
                                <h4>We are committed to providing our customers with the highest quality products and services.
                                </h4>
                            </div>

                            <div className='main2'>
                                <h3>Innovation</h3>
                                <img src={innovation} alt='Hero' />
                                <h4>We are always innovating to meet the changing needs of the market.
                                </h4>
                            </div>

                            <div className='main2'>
                                <h3>Customer service</h3>
                                <img src={customer} alt='Hero' />
                                <h4>We are dedicated to providing our customers with the best possible service.
                                </h4>
                            </div>
                        </div>
                    </div>


                </div>
            </>

        );
    }
}

export default About;
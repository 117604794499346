import React, { useState } from 'react';
import { getDatabase, ref, push, set, serverTimestamp } from 'firebase/database';
import '/Users/hirenmasaliya/ReactDemo/buildart/src/Css/Contact.css';
import customer from "/Users/hirenmasaliya/ReactDemo/buildart/src/Images/Customer_servies.png";

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false); // Track submission state
  const [submitSuccess, setSubmitSuccess] = useState(false); // Track successful submission
  const [submitError, setSubmitError] = useState(null); // Store any error

  const handleSubmit = async (event) => {
    event.preventDefault();

    setSubmitting(true); // Indicate submission in progress
    setSubmitSuccess(false); // Reset success flag
    setSubmitError(null); // Clear any previous error

    try {
      const database = getDatabase();
      const messageRef = ref(database, 'Messages'); // Reference to the 'Messages' node

      // Push new data to the 'Messages' node
      const newMessageRef = push(messageRef);
      const messageId = newMessageRef.key;

      const timestamp = serverTimestamp();

      // Set the data for the new message
      await set(newMessageRef, {
        Id: messageId,
        Name: name,
        Email: email,
        MobileNumber: mobileNumber,
        Message: message,
        Timestamp: timestamp,
      });

      // Clear form fields and show success message
      setName('');
      setEmail('');
      setMobileNumber('');
      setMessage('');
      setSubmitSuccess(true);

    } catch (error) {
      console.error('Error submitting message:', error);
      setSubmitError('Submission failed. Please try again later.'); // Display error message
    } finally {
      setSubmitting(false); // Indicate submission completion
    }
  };

  return (
    <div className='contact'>
      <form onSubmit={handleSubmit}>
        <h2>Contact Us</h2>
        {submitSuccess && <p className="success">Message submitted successfully!</p>}
        {submitError && <p className="error">{submitError}</p>}
        <input
          type='text'
          placeholder='Full Name'
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type='email'
          placeholder='Enter Email'
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type='text'
          placeholder='Enter Mobile No'
          required
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        />
        <textarea
          rows={8}
          placeholder='Message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button type='submit' disabled={submitting}>
          {submitting ? 'Submitting...' : 'Submit'}
        </button>
      </form>
      <img src={customer} alt='Hero' />
    </div>
  );
};

export default Contact;

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '/Users/hirenmasaliya/ReactDemo/buildart/src/Images/logo.png';
import '/Users/hirenmasaliya/ReactDemo/buildart/src/Css/Navbar.css';

const Navbar = () => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <div className='nav'>
        <img className='logo' src={logo} alt='Logo' />
        <button className='menu-btn' onClick={toggleMenu}>
          <span className='menu-icon'>&#9776;</span>
        </button>

        <div className={`links ${menuOpen ? 'open' : ''}`}>
          <button className='close-btn' onClick={closeMenu}>
            <span>&times;</span>
          </button>
          <ul>
            <Link
              to="/"
              className={`navbar-link ${location.pathname === '/' ? 'navbar-link-active' : ''}`}
              onClick={closeMenu}
            >
              Home
            </Link>
            <Link
              to="/about"
              className={`navbar-link ${location.pathname === '/about' ? 'navbar-link-active' : ''}`}
              onClick={closeMenu}
            >
              About
            </Link>
            <Link to="/products"
              className={`navbar-link ${location.pathname === '/products' ? 'navbar-link-active' : ''}`}
              onClick={closeMenu}
            >
              Products</Link>
            <Link to="/contact"
              className={`navbar-link ${location.pathname === '/contact' ? 'navbar-link-active' : ''}`}
              onClick={closeMenu}
            >
              Contact
            </Link>
          </ul>
        </div>
      </div >
    </>
  );
};

export default Navbar;

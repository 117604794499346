import React from 'react';
import quality from "/Users/hirenmasaliya/ReactDemo/buildart/src/Images/quality.png";
import innovation from "/Users/hirenmasaliya/ReactDemo/buildart/src/Images/innovation.png";
import customer from "/Users/hirenmasaliya/ReactDemo/buildart/src/Images/Customer_servies.png";
import relationship from "/Users/hirenmasaliya/ReactDemo/buildart/src/Images/relationship.webp";
import cost from "/Users/hirenmasaliya/ReactDemo/buildart/src/Images/cost.webp";
import delivery from "/Users/hirenmasaliya/ReactDemo/buildart/src/Images/Fast Delivery.webp";

import '/Users/hirenmasaliya/ReactDemo/buildart/src/Css/QualityObjection.css';


const QualityObjection = () => {

    return (
        <>
            <div className='first'>
                <h2>QUALITY OBJECTION</h2>
                <div className='maindiv'>
                    <div className='main1'>
                        <img src={quality} alt='Hero' />
                        <h3>Quality Products</h3>
                    </div>

                    <div className='main1'>
                        <img src={innovation} alt='Hero' />
                        <h3>Technological Improvement</h3>
                        
                    </div>

                    <div className='main1'>
                        <img src={customer} alt='Hero' />
                        <h3>Fully Customer service</h3>
                    
                    </div>

                    <div className='main1'>
                        <img src={relationship} alt='Hero' />
                        <h3>Stronger Relationship With Supplier</h3>
                    </div>

                    <div className='main1'>
                        <img src={cost} alt='Hero' />
                        <h3>Cost Effectiveness</h3>
                        
                    </div>

                    <div className='main1'>
                        <img src={delivery} alt='Hero' />
                        <h3>Fast Delivery</h3>
                    
                    </div>
                </div>
            </div>
        </>
    );
}

export default QualityObjection;
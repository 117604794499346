import React from "react";
import { Link } from 'react-router-dom';
import Adhesive1 from "/Users/hirenmasaliya/ReactDemo/buildart/src/Products Images/image 32.png";
import Adhesive2 from "/Users/hirenmasaliya/ReactDemo/buildart/src/Products Images/image 33.png";
import Adhesive3 from "/Users/hirenmasaliya/ReactDemo/buildart/src/Products Images/image 34.png";
import Adhesive4 from "/Users/hirenmasaliya/ReactDemo/buildart/src/Products Images/image 35.png";
import Adhesive5 from "/Users/hirenmasaliya/ReactDemo/buildart/src/Products Images/image 36.png";
import tilesGrout from "/Users/hirenmasaliya/ReactDemo/buildart/src/Products Images/image 37.png";
import buildpoxy from "/Users/hirenmasaliya/ReactDemo/buildart/src/Products Images/image 40.png";
import jari from "/Users/hirenmasaliya/ReactDemo/buildart/src/Products Images/image 39.png";
import spacer from "/Users/hirenmasaliya/ReactDemo/buildart/src/Products Images/image 38.png";
import tilecleaner from "/Users/hirenmasaliya/ReactDemo/buildart/src/Products Images/image 41.png";
import bildbond from "/Users/hirenmasaliya/ReactDemo/buildart/src/Products Images/image 42.png";

import '/Users/hirenmasaliya/ReactDemo/buildart/src/Css/Products.css';

const Products = () => {

    return (
        <>
            <div className="maincard">
                <div className='card'>
                    <img src={Adhesive1} alt='Hero' />
                    <h3>BUILD FIX-01 TILE ADHESIVE (GREY BASE)</h3>
                    <p>Adhesive tiles On Floor & Ceramic Tiles On Wall
                        Size up To 2x2</p>
                    <div className="text">
                        <p>Buy Products to Contact Now</p>
                        <Link to={"https://wa.me/+916355502404"} target="_blank"><i class="fa fa-whatsapp" ></i></Link>
                    </div>

                </div>

                <div className='card'>
                    <img src={Adhesive2} alt='Hero' />
                    <h3>BUILD FIX-02 TILE ADHESIVE (GREY BASE)</h3>
                    <p>Adhesive For Fixing Ceramic, Vitrified Tiles, Marble & Etc Size up To (2 x 2, 2 x 4)</p>
                    <div className="text">
                        <p>Buy Products to Contact Now</p>
                        <Link to={"https://wa.me/+916355502404"} target="_blank"><i class="fa fa-whatsapp" ></i></Link>
                    </div>
                </div>

                <div className='card'>
                    <img src={Adhesive3} alt='Hero' />
                    <h3>BUILD FIX-03 TILE ADHESIVE (GREY BASE)</h3>
                    <p>Ahesive For Fixing Big Vitrified, Marbles,Granites On Wall & Floor Also Exterier Applications.</p>
                    <div className="text">
                        <p>Buy Products to Contact Now</p>
                        <Link to={"https://wa.me/+916355502404"} target="_blank"><i class="fa fa-whatsapp" ></i></Link>
                    </div>
                </div>

                <div className='card'>
                    <img src={Adhesive4} alt='Hero' />
                    <h3>BUILD FIX-02 TILE ADHESIVE (WHITE BASE)</h3>
                    <p>Adhesive For Fixing Ceramic, Vitrified Tiles, Marble & Etc Size up To (2 x 2, 2 x 4)</p>
                    <div className="text">
                        <p>Buy Products to Contact Now</p>
                        <Link to={"https://wa.me/+916355502404"} target="_blank"><i class="fa fa-whatsapp" ></i></Link>
                    </div>
                </div>

                <div className='card'>
                    <img src={Adhesive5} alt='Hero' />
                    <h3>BUILD FIX-03 TILE ADHESIVE (WHITE BASE)</h3>
                    <p>Ahesive For Fixing Big Vitrified, Marbles,Granites On Wall & Floor Also Exterier Applications.</p>
                    <div className="text">
                        <p>Buy Products to Contact Now</p>
                        <Link to={"https://wa.me/+916355502404"} target="_blank"><i class="fa fa-whatsapp" ></i></Link>
                    </div>
                </div>

                <div className='card'>
                    <img src={tilesGrout} alt='Hero' />
                    <h3>A01 TILE GROUT</h3>
                    <p>White Cement Base Polymer Modified Grout For Filling Tile Tile Joints .</p>
                    <div className="text">
                        <p>Buy Products to Contact Now</p>
                        <Link to={"https://wa.me/+916355502404"} target="_blank"><i class="fa fa-whatsapp" ></i></Link>
                    </div>
                </div>

                <div className='card'>
                    <img src={buildpoxy} alt='Hero' />
                    <h3>A02 - BUILD POXY EPOXY TILE GROUNT</h3>
                    <p>Epoxy Grout For Filling Tile Joints</p>
                    <div className="text">
                        <p>Buy Products to Contact Now</p>
                        <Link to={"https://wa.me/+916355502404"} target="_blank"><i class="fa fa-whatsapp" ></i></Link>
                    </div>
                </div>

                <div className='card'>
                    <img src={jari} alt='Hero' />
                    <h3>JARI (SPARKLE)</h3>
                    <p>For Sparkling Epoxy Grout.</p>
                    <div className="text">
                        <p>Buy Products to Contact Now</p>
                        <Link to={"https://wa.me/+916355502404"} target="_blank"><i class="fa fa-whatsapp" ></i></Link>
                    </div>
                </div>

                <div className='card'>
                    <img src={spacer} alt='Hero' />
                    <h3>TILE SPACERS</h3>
                    <p>For Creating Uniform Space Between Two Tiles.</p>
                    <div className="text">
                        <p>Buy Products to Contact Now</p>
                        <Link to={"https://wa.me/+916355502404"} target="_blank"><i class="fa fa-whatsapp" ></i></Link>
                    </div>
                </div>

                <div className='card'>
                    <img src={tilecleaner} alt='Hero' />
                    <h3>A03 TILE CLEANER</h3>
                    <p>High Performance Premium Quality Tiles Cleaner .</p>
                    <div className="text">
                        <p>Buy Products to Contact Now</p>
                        <Link to={"https://wa.me/+916355502404"} target="_blank"><i class="fa fa-whatsapp" ></i></Link>
                    </div>
                </div>

                <div className='card'>
                    <img src={bildbond} alt='Hero' />
                    <h3>SO1-SBR+ BUILD BOND</h3>
                    <p>Water Proofing Solution</p>
                    <div className="text">
                        <p>Buy Products to Contact Now</p>
                        <Link to={"https://wa.me/+916355502404"} target="_blank"><i class="fa fa-whatsapp" ></i></Link>
                    </div>
                </div>
            </div>

            
        </>
    );
}

export default Products;
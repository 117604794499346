import React from 'react';
import '/Users/hirenmasaliya/ReactDemo/buildart/src/Css/Home.css';
import hero from '/Users/hirenmasaliya/ReactDemo/buildart/src/Images/hero.webp';
import img1 from '/Users/hirenmasaliya/ReactDemo/buildart/src/product/Profile banner - 1.png';
import QualityObjection from './QualityObjection';


class Home extends React.Component {
    state = {}
    render() {
        return (
            <>
                <div className='main'>
                    <div className='left'>
                        <h1 className='heading'>We Build The Way You Want</h1>
                        <h3 name='description' className='discription'>Buildart Industries specializes in the manufacturing of high-quality building materials, including epoxy grout, adhesives, fillers, and more. Our products are meticulously crafted to meet the stringent standards of the construction industry, ensuring durability, strength, and reliability.</h3>

                        <h3 name='description' className='discription'>At Buildart Industries, we take pride in our commitment to innovation, quality, and customer satisfaction. With our comprehensive line of building materials, we strive to be the preferred choice for construction professionals seeking reliable and high-performance solutions for their projects.</h3>
                    </div>
                    <div className='right'>
                        <img src={hero} alt='Hero' />
                    </div>
                </div>

                <div className='pdiv'>
                    <h2>OUR PRODUCTS</h2>
                    <div className='pic'>
                        <img src={img1} alt='Hero' />
                    </div>
                </div>

                <QualityObjection />

            </>

        );
    }
}

export default Home;
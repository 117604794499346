import React, { useState } from "react";
import { Link, useNavigate, Outlet } from 'react-router-dom';
import '/Users/hirenmasaliya/ReactDemo/buildart/src/Css/Footer.css';
import logo from '/Users/hirenmasaliya/ReactDemo/buildart/src/Images/Logo (2).png';

const Footer = () => {
    const [currentPage, setCurrentPage] = useState('home');
    const navigate = useNavigate();

    const handlePageChange = (page) => {
        setCurrentPage(page);
        navigate(`/${page}`);
    };

    return (
        <>
            <div className="footer">

                <div className="comInfo">
                    <h1>Contact us</h1>
                    <p>Mfg.& Mkt. by:</p>
                    <h2>BUILDART INDUSTRIES</h2>
                    <p>Rajkot, Gujarat, India</p>
                    <p>Email: buildartindustries@gmail.com</p>
                    <p>Contact: +91 63555 02404</p>
                </div>
                <div className="linked">
                    <ul id="menu-1-d808c5b" className="elementor-nav-menu sm-vertical" >
                            <li className={currentPage === 'home' ? 'selected' : 'unselected'}>
                                <Link to="/" onClick={() => handlePageChange('home')}>Home</Link>
                            </li>
                            <li className={currentPage === 'about' ? 'selected' : 'unselected'}>
                                <Link to="/about">About Us</Link>
                            </li>
                            <li className={currentPage === 'products' ? 'selected' : 'unselected'}>
                                <Link to="/products" className="elementor-item" onClick={() => handlePageChange('our-products')}>Our Products</Link>
                            </li>
                            <li className={currentPage === 'blogs' ? 'selected' : 'unselected'}>
                                <Link to="/blogs" className="elementor-item" onClick={() => handlePageChange('blogs')}>Blogs</Link>
                            </li>
                            <li className={currentPage === 'contact-us' ? 'selected' : 'unselected'}>
                                <Link to="/contact" className="elementor-item" onClick={() => handlePageChange('contact-us')}>Contact Us</Link>
                            </li>
                        </ul>
                </div>

                <div className="newsletter">
                    <h2>Newsletter</h2>
                    <p>Send us a newsletter to get update</p>
                    <input type="email" placeholder="Your Email" />
                    <button>Submit</button>
                </div>

                <div className="socialimg">
                    <img src={logo} alt='logo' />
                    <div className="social">
                        <Link to={"https://wa.me/+916355502404"} target="_blank"><i id="whatsapp" class="fa fa-whatsapp" ></i></Link>
                        <Link to={"https://www.instagram.com/buildart_industries?igsh=cG1vbWhhYmdzNW5j"} target="_blank"><i id="instagram" class="fa fa-instagram"></i></Link>
                        <Link to={"mailto:buildartindustries@gmail.com"} target="_blank"><i id="email" class="fa fa-envelope"></i></Link>
                        
                    </div>
                </div>
            </div>

            <Outlet />
        </>
    );
}

export default Footer;